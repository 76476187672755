<template>
  <div class="container">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="form--layout">Layout:</label>
              <select class="form-control" id="form--layout">
                <option value="row">row</option>
                <option value="grid">grid</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="form--product-box-layout-select"
                >Product Box Layout:</label
              >
              <select class="form-control" id="form--product-box-layout-select">
                <option value="inline">inline</option>
                <option value="card">card</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <label for="form--inject-as-select">Inject As:</label>
            <select class="form-control" id="form--inject-as-select">
              <option value="input">input</option>
              <option value="button">button</option>
            </select>
          </div>
          <div class="col-md-4" v-if="source == 'shoptet'">
            <v-switch
              @change="toggleLive()"
              v-model="shoptet_info.is_live"
              label="Upsearch on Shoptet Live MODE"
            ></v-switch>
          </div>
        </div>
      </div>
    </div>
    <div id="upSearchSuggest"></div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import {
  GET_SHOPTET,
  TOGGLE_SHOPTET_LIVE
} from "@/core/services/store/shoptet.module";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      config: {
        resultsPageUrlPath: "/#/query-tester",
        apiToken: this.token,
        showAddToCart: true,
        addToCartButtonIcon: "search",
        addToCart: "addToCart",
        layout: "row",
        productBoxLayout: "inline",
        showTopProduct: true,
        suggestAltButtonIcon: "search",
        suggestAltButtonSize: "md",
        suggestAltButtonVariant: "secondary",
        showSuggestAltButtonText: false
      }
    };
  },
  methods: {
    toggleLive() {
      this.$store.dispatch(TOGGLE_SHOPTET_LIVE).then(() => {
        Swal.fire("The SHOPTET MODE is changed now!", "", "success");
      });
    },
    renderWidget() {
      this.config.apiToken = this.token;
      if (this.config.apiToken !== undefined) {
        window.upSearchSuggest("#upSearchSuggest", this.config);
      }
    }
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
    changeCollection() {
      this.reloadData();
    },
    changeToken() {
      this.renderWidget();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    changeCollection() {
      return this.selectedCollection.id;
    },
    changeToken() {
      return this.token;
    },
    ...mapState({
      errors: state => state.searchengine.errors,
      message: state => state.searchengine.message,
      hasMessage: state => state.searchengine.hasMessage,
      loading: state => state.searchengine.loading,
      took: state => state.searchengine.took,
      token: state => state.project.current_project.token,
      source: state => state.project.current_project.source,
      shoptet_info: state => state.shoptet
    }),
    ...mapGetters(["selectedCollection", "currentProject"])
  },
  beforeCreate() {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Query Tester" }]);
    this.$store.dispatch(GET_SHOPTET);

    let script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://upsearch.vercel.app/upSearchSuggest.js"
    );
    document.head.appendChild(script);

    let style = document.createElement("link");
    style.setAttribute("href", "https://upsearch.vercel.app/upsearch.css");
    style.setAttribute("rel", "stylesheet");
    document.head.appendChild(style);

    window.addToCart = code => alert("Produkt " + code + " přidán do košíku");

    const layoutSelect = document.getElementById("form--layout");
    layoutSelect.addEventListener(
      "change",
      event => {
        this.config.layout = event.currentTarget.value;
        this.renderWidget();
      },
      false
    );

    const productBoxLayoutSelect = document.getElementById(
      "form--product-box-layout-select"
    );
    productBoxLayoutSelect.addEventListener(
      "change",
      event => {
        this.config.productBoxLayout = event.currentTarget.value;
        this.renderWidget();
      },
      false
    );

    const injectAsSelect = document.getElementById("form--inject-as-select");
    injectAsSelect.addEventListener(
      "change",
      event => {
        this.config.injectAs = event.currentTarget.value;
        this.renderWidget();
      },
      false
    );

    this.renderWidget();
  }
};
</script>
